import {
  CaretUpIcon,
  CaretDownIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  QuestionMarkCircledIcon,
  DotsHorizontalIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import { LinkIcon } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import type { PostPaginationType } from 'services/post';
import { cn } from 'utils/utils';
import { Button } from '~/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '~/ui/popover';
import { CreatePostContext } from '../../../providers/create-post';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '~/ui/sheet';
import { Card } from '~/ui/card';
import { useWindowSize } from 'usehooks-ts';
import Link from 'next/link';
type PostNavigationProps = {
  postPaginationParams: PostPaginationType;
  setPostPaginationParams: React.Dispatch<React.SetStateAction<PostPaginationType>>;
  pageCount: number;
};

function PostNavigation({ postPaginationParams, setPostPaginationParams, pageCount }: PostNavigationProps) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { start, limit, order } = postPaginationParams;
  const setStart = (start: number) => setPostPaginationParams((prev) => ({ ...prev, start }));
  const setOrder = (order: PostPaginationType['order']) => setPostPaginationParams((prev) => ({ ...prev, order }));
  const setLimit = (limit: number) => setPostPaginationParams((prev) => ({ ...prev, limit }));

  const totalPages = pageCount;
  const currentPage = start / limit + 1;
  const iconSize = 'w-24 h-24';

  const renderPageNumbers = () => {
    const pages = [];
    const totalVisiblePages = 3;

    let startPage, endPage;

    if (currentPage <= 2) {
      startPage = 1;
      endPage = Math.min(totalPages, totalVisiblePages);
    } else if (currentPage >= totalPages - 1) {
      startPage = Math.max(1, totalPages - (totalVisiblePages - 1));
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          size={'icon'}
          disabled={i === currentPage}
          className={cn('text-xl', i === currentPage ? 'underline' : 'text-white')}
          key={i}
          variant={'link'}
          onClick={() => setStart((i - 1) * limit)}
        >
          {i}
        </Button>,
      );
    }

    while (pages.length < totalVisiblePages) {
      pages.push(
        <Button
          size={'icon'}
          disabled
          className={cn('text-xl text-gray-500')}
          key={`empty-${pages.length}`}
          variant={'link'}
        >
          {' '}
        </Button>,
      );
    }

    return pages;
  };

  const copyLink = () => {
    try {
      toast.promise(copy(window.location.href), {
        loading: 'Copying link to clipboard...',
        success: 'Link copied to clipboard',
        error: 'Failed to copy link to clipboard',
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to copy link to clipboard');
    }
  };

  const limitOptions = ['1', '25', '100'] as const;

  const { pollState } = useContext(CreatePostContext);
  const [openPollInfo, setOpenPollInfo] = useState(false);
  useEffect(() => {
    setOpenPollInfo(pollState.pollStartTime != null);
  }, [pollState.pollStartTime]);

  return (
    <>
      <Sheet>
        <SheetTrigger tabIndex={-1} className=''>
          <DotsHorizontalIcon className='w-10 h-10 icon-button' />
        </SheetTrigger>
        <Link href='/posts/new' tabIndex={-1} className='flex items-center gap-2'>
          <PlusCircledIcon className='w-8 h-8 icon-button' />
        </Link>
        <SheetContent className='flex flex-col gap-6 overscroll-none touch-none p-4'>
          <SheetHeader>
            <SheetTitle>
              <span className='text-lg font-semibold'>Post Navigation</span>
            </SheetTitle>
          </SheetHeader>
          <Card className='flex flex-col gap-4 p-4'>
            <span className='text-gray-400'>Order</span>
            <div className='flex w-full gap-4'>
              <Button tabIndex={-1} variant={order === 'asc' ? 'default' : 'link'} onClick={() => setOrder('asc')}>
                <CaretUpIcon className='w-6 h-6' />
                {!isMobile && 'Asc'}
              </Button>
              <Button tabIndex={-1} variant={order === 'desc' ? 'default' : 'link'} onClick={() => setOrder('desc')}>
                <CaretDownIcon className='w-6 h-6' />
                {!isMobile && 'Desc'}
              </Button>
            </div>
          </Card>
          <Card className='flex flex-col gap-4 p-4'>
            <span className='text-gray-400'>Posts per page</span>
            <div className='flex w-full gap-4'>
              {limitOptions.map((option) => (
                <Button
                  key={`limit-${option}`}
                  tabIndex={-1}
                  variant={limit === Number(option) ? 'default' : 'link'}
                  onClick={() => setLimit(Number(option))}
                >
                  {option}
                </Button>
              ))}
            </div>
          </Card>
          <Card className='flex flex-col gap-4 p-4'>
            <span className='text-gray-400'>Total Pages</span>
            <span className='w-full'>{pageCount}</span>
          </Card>
          <Card className='flex flex-col gap-4 p-4'>
            <span className='text-gray-400'>Copy link to clipboard</span>
            <Button
              tabIndex={-1}
              className='flex items-center justify-between gap-4'
              title='Copy link'
              variant={'link'}
              onClick={copyLink}
            >
              <LinkIcon className='w-6 h-6' />
              Copy link
            </Button>
          </Card>
        </SheetContent>
      </Sheet>
      <Popover onOpenChange={setOpenPollInfo} open={openPollInfo}>
        <PopoverTrigger asChild className='block'>
          <Button className={pollState.pollStartTime == null ? 'hidden' : 'flex'} size={'icon'} variant={'link'}>
            <QuestionMarkCircledIcon className={iconSize} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='flex flex-col w-full gap-2 p-4 rounded-md shadow-lg '>
          <div className='mb-2 text-lg font-semibold'>Post Status</div>
          <div className='text-sm text-wrap'>Checking the status of the post on-chain.</div>
          <div className='text-sm'>This may take a few minutes.</div>
          <div className='mb-2'>
            <span className='text-gray-600'>Polling Started At: </span>
            <span className='font-medium'>
              {pollState.pollStartTime ? new Date(pollState.pollStartTime).toLocaleTimeString() : 'N/A'}
            </span>
          </div>
          <div className='mb-2'>
            <span className='text-gray-600'>Refetch Count: </span>
            <span className='font-medium'>{pollState.refetchCount}</span>
          </div>
          <div className='mb-2'>
            <span className='text-gray-600'>Success: </span>
            <span className={`font-medium ${pollState.success ? 'text-green-600' : 'text-red-600'}`}>
              {pollState.success ? 'Yes' : 'No'}
            </span>
          </div>
          {pollState.error && (
            <div className='mb-2'>
              <span className='text-gray-600'>Error: </span>
              <span className='font-medium text-red-600'>{pollState.error}</span>
            </div>
          )}
          {!pollState.success && !pollState.error && (
            <div className='mb-2'>
              <span className='text-gray-600'>Polling in Progress...</span>
            </div>
          )}
        </PopoverContent>
      </Popover>

      <div className='flex items-center self-end justify-end w-full gap-2 '>
        <Button size={'icon'} variant={'link'} onClick={() => setStart(0)} disabled={start === 0}>
          <DoubleArrowLeftIcon className={iconSize} />
        </Button>
        {renderPageNumbers()}
        <Button
          size={'icon'}
          variant={'link'}
          onClick={() => setStart((pageCount - 1) * limit)}
          disabled={start + limit >= pageCount * limit}
        >
          <DoubleArrowRightIcon className={iconSize} />
        </Button>
      </div>
    </>
  );
}

export default PostNavigation;
