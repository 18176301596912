'use client';

import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { PostPaginationType, usePosts, type Post } from '../../services/post';

import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import PostCard from './components/card';
import PostNavigation from './components/navigation';
import Loader from '~/ui/loader';
import { Popover } from '~/ui/popover';
import { useNavigationContext } from 'providers/bottom-navigation';

const defaultPostPaginationParams: PostPaginationType = { start: 0, limit: 25, order: 'desc', afterId: 0 };

type PostPageProps = {
  address?: string;
};

function PostPage({ address }: PostPageProps) {
  const [postPaginationParams, setPostPaginationParams] = useQueryParams({
    start: withDefault(NumberParam, defaultPostPaginationParams.start, true),
    limit: withDefault(NumberParam, defaultPostPaginationParams.limit, true),
    order: withDefault(StringParam, defaultPostPaginationParams.order, true),
    afterId: withDefault(NumberParam, defaultPostPaginationParams.afterId, true),
    address: withDefault(StringParam, address || '', true),
  }) as unknown as [PostPaginationType, Dispatch<SetStateAction<PostPaginationType>>];

  const { data: postResponse, isError, isFetching } = usePosts(postPaginationParams);


  const [expandedPosts, setExpandedPosts] = useState<number[]>([]);

  const handleExpandPost = (id: number) => {
    setExpandedPosts((prev) => (prev.includes(id) ? prev.filter((p) => p !== id) : [...prev, id]));
  };

  const { setNavigationBarContent } = useNavigationContext();

  useEffect(() => {
    setNavigationBarContent(
      <PostNavigation
        postPaginationParams={postPaginationParams}
        setPostPaginationParams={setPostPaginationParams}
        pageCount={postResponse?.pageCount || 0}
      />
    );

    return () => {
      setNavigationBarContent(null);
    };
  }, [postPaginationParams, postResponse, setPostPaginationParams, setNavigationBarContent]);

  return (
    <>
      <Loader isFetching={isFetching} isError={isError}>
        <Popover>
          {postResponse?.posts && postResponse.posts.length === 0 && <div>No posts found</div>}
          <div className='h-full overflow-y-auto md:overscroll-none'>
            <div className='flex flex-col pb-2 md:px-1 gap-y-2'>
              {postResponse?.posts &&
                postResponse?.posts.map((post: Post) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    expanded={expandedPosts.includes(post.id)}
                    onToggleExpand={handleExpandPost}
                  />
                ))}
            </div>
          </div>
        </Popover>
      </Loader>
    </>
  );
};

export default PostPage
